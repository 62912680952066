// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    admins: []
};

const slice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET ADMINS
        getAdminsSuccess(state, action) {
            state.admins = action.payload.adminList;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getAdmins(data) {
    return async () => {
        try {
            const response = await axios.post('/api/admin/list', data,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }
            );
            dispatch(slice.actions.getAdminsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function changePassword(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/admin/password/change', data, 
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}