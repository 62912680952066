import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { Link } from '@mui/material';

// project imports
import { DASHBOARD_PATH } from 'config';
import logo from 'assets/images/logo.png';

// ==============================|| MAIN LOGO ||============================== //

const HeaderLogoSection = () => (
    <Link component={RouterLink} to={DASHBOARD_PATH}>
        <img style={{width: '150px'}} src={logo} alt="Caterpillar" />
    </Link>
);

export default HeaderLogoSection;
