// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    count: 0,
    order: {},
    orders: [],
    products: [],
    productreviews: []
};

const slice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET ORDERS
        getOrdersSuccess(state, action) {
            state.orders = action.payload.orderList;
            state.count = action.payload.total;
        },

        // GET ORDER
        getOrderSuccess(state, action) {
            state.order = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getOrders(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/order/list', data,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.getOrdersSuccess(response.data));
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addOrder(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/order/add', data, 
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }
            );
            if(callback){
                callback(response.data.order.id);
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getOrder(id, callback) {
    return async () => {
        try {
            const response = await axios.get('/api/order/details/'+id, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.getOrderSuccess(response.data.order));
            if(callback){
                callback(response.data.order);
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };

}

export function editOrder(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/order/edit', data, 
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.getOrderSuccess(response.data.order));
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addInvoice(data, callback) {
    console.log(data)
    return async () => {
        try {
            const response = await axios.post('/api/order/invoice/add', data, 
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.getOrderSuccess(response.data.order));
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


