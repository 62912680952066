// export const JWT_API = {
//     secret: 'SECRET-KEY',
//     timeout: '1 days'
// };

// export const FIREBASE_API = {
//     apiKey: 'AIzaSyBernKzdSojh_vWXBHt0aRhf5SC9VLChbM',
//     authDomain: 'bermailvision.firebaseapp.com',
//     projectId: 'mailvision',
//     storageBucket: 'mailvision.appspot.com',
//     messagingSenderId: '901111229354',
//     appId: '1:901111229354:web:a5ae5aa95486297d69d9d3',
//     measurementId: 'G-MGJHSL8XW3'
// };

export const AUTH0_API = {
    client_id: 'B9io3JYXrSqI2VFA4jbQmEv6KAvw9hyX',
    domain: 'caterpillar.au.auth0.com',
    audience: 'caterpillar-crm',
    cacheLocation: 'localstorage',
    useRefreshTokens: true
};

// export const AWS_API = {
//     poolId: 'us-east-1_AOfOTXLvD',
//     appClientId: '3eau2osduslvb7vks3vsh9t7b0'
// };

// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
// like '/mailvision/react/default'
export const BASE_PATH = '';

export const DASHBOARD_PATH = '/workspace/list';

const config = {
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 8,
    outlinedFilled: true,
    navType: 'light', // light, dark
    presetColor: 'theme6', // default, theme1, theme2, theme3, theme4, theme5, theme6
    locale: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    rtlLayout: false,
    container: false
};

export default config;
