// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconUser, IconBasket, IconMessages, IconReceipt2, IconMail, IconCalendar, IconClipboard, IconBorderAll, IconDashboard, IconPackage } from '@tabler/icons';

// constant
const icons = {
    IconUser,
    IconBasket,
    IconMessages,
    IconReceipt2,
    IconMail,
    IconCalendar,
    IconClipboard,
    IconBorderAll,
    IconDashboard,
    IconPackage
};

// ==============================|| APPLICATION MENU ITEMS ||============================== //

const application = {
    id: 'application',
    type: 'group',
    children: [
        // {
        //     id: 'dashboard',
        //     title: <FormattedMessage id="dashboard" />,
        //     type: 'item',
        //     url: '/dashboard',
        //     icon: icons.IconDashboard,
        //     breadcrumbs: false
        // },
        {
            id: 'workspace',
            title: <FormattedMessage id="workspace" />,
            type: 'collapse',
            icon: icons.IconUser,
            children: [
                {
                    id: 'workspace-list',
                    title: <FormattedMessage id="workspace-list" />,
                    type: 'item',
                    url: '/workspace/list',
                    breadcrumbs: false
                },
                {
                    id: 'order-list',
                    title: <FormattedMessage id="order-list" />,
                    type: 'item',
                    url: '/order/list',
                    breadcrumbs: false
                },
                // {
                //     id: 'subscription-list',
                //     title: <FormattedMessage id="subscription-list" />,
                //     type: 'item',
                //     url: '/client/subscription/list',
                //     breadcrumbs: false
                // },
                // {
                //     id: 'create-invoice',
                //     title: <FormattedMessage id="create-invoice" />,
                //     type: 'item',
                //     url: '/client/create-invoice',
                //     breadcrumbs: false
                // }
            ]
        },
        {
            id: 'campaign',
            title: <FormattedMessage id="campaign" />,
            type: 'collapse',
            icon: icons.IconPackage,
            children: [
                {
                    id: 'campaign-list',
                    title: <FormattedMessage id="campaign-list" />,
                    type: 'item',
                    url: '/campaign/list',
                    breadcrumbs: false
                },
                {
                    id: 'creative-list',
                    title: <FormattedMessage id="creative-list" />,
                    type: 'item',
                    url: '/creative/list',
                    breadcrumbs: false
                },
                {
                    id: 'text-list',
                    title: <FormattedMessage id="text-list" />,
                    type: 'item',
                    url: '/text/list',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'email-template',
            title: <FormattedMessage id="email-template" />,
            type: 'item',
            url: '/email-template/list',
            icon: icons.IconMail,
            breadcrumbs: false
        },
        {
            id: 'price',
            title: <FormattedMessage id="pricing" />,
            type: 'item',
            url: '/pricing',
            icon: icons.IconReceipt2,
            breadcrumbs: false
        },
        // {
        //     id: 'calendar',
        //     title: <FormattedMessage id="calendar" />,
        //     type: 'item',
        //     url: '/app/calendar',
        //     icon: icons.IconCalendar,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'basedata',
        //     title: <FormattedMessage id="base-data" />,
        //     type: 'collapse',
        //     icon: icons.IconBorderAll,
        //     children: [
        //         // {
        //         //     id: 'industry-list',
        //         //     title: <FormattedMessage id="industry" />,
        //         //     type: 'item',
        //         //     url: '/basedata/industry/list',
        //         //     breadcrumbs: false
        //         // },
        //         // {
        //         //     id: 'subindustry-list',
        //         //     title: <FormattedMessage id="subindustry" />,
        //         //     type: 'item',
        //         //     url: '/basedata/subindustry/list',
        //         //     breadcrumbs: false
        //         // },
        //         {
        //             id: 'brand-list',
        //             title: <FormattedMessage id="brand" />,
        //             type: 'item',
        //             url: '/basedata/brand/list',
        //             breadcrumbs: false
        //         },
        //         // {
        //         //     id: 'tag-list',
        //         //     title: <FormattedMessage id="tag" />,
        //         //     type: 'item',
        //         //     url: '/basedata/tag-list',
        //         //     breadcrumbs: false
        //         // },
        //         // {
        //         //     id: 'domain-list',
        //         //     title: <FormattedMessage id="domain" />,
        //         //     type: 'item',
        //         //     url: '/basedata/domain/list',
        //         //     breadcrumbs: false
        //         // }
        //     ]
        // }
    ]
};

export default application;
