// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// project imports
import snackbarReducer from './slices/snackbar';
import backdropReducer from './slices/backdrop';
import contactReducer from './slices/contact';
import productReducer from './slices/product';
import chatReducer from './slices/chat';
import calendarReducer from './slices/calendar';
import mailReducer from './slices/mail';
import userReducer from './slices/user';
import cartReducer from './slices/cart';
import kanbanReducer from './slices/kanban';
import menuReducer from './slices/menu';

import emailTemplateReducer from './slices/email-template';

import basedataReducer from './slices/basedata';
import subscriptionReducer from './slices/subscription';

import workspaceReducer from './slices/workspace';
import orderReducer from './slices/order';
import campaignReducer from './slices/campaign';

import brandReducer from './slices/brand';
import subindustryReducer from './slices/subindustry';
import industryReducer from './slices/industry';

import adminReducer from './slices/admin';
import notificationReducer from './slices/notification';
// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    snackbar: snackbarReducer,
    backdrop: backdropReducer,
    cart: persistReducer(
        {
            key: 'cart',
            storage,
            keyPrefix: 'caterpillar-'
        },
        cartReducer
    ),
    kanban: kanbanReducer,
    contact: contactReducer,
    product: productReducer,
    chat: chatReducer,
    calendar: calendarReducer,
    mail: mailReducer,
    user: userReducer,
    menu: menuReducer,
    emailTemplate: emailTemplateReducer,
    basedata: basedataReducer,
    subscription: subscriptionReducer,
    workspace: workspaceReducer,
    order: orderReducer,
    campaign: campaignReducer,
    brand: brandReducer,
    subindustry: subindustryReducer,
    industry: industryReducer,
    admin: adminReducer,
    notification: notificationReducer
});

export default reducer;
