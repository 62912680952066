import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// dashboard routing
const Dashboard = Loadable(lazy(() => import('views/dashboard')));

// admin routing
const AdminProfile = Loadable(lazy(() => import('views/users/account-profile/Profile1')));

// workspace routing
const WorkspaceList = Loadable(lazy(() => import('views/workspace/WorkspaceList')));
const WorkspaceDetails = Loadable(lazy(() => import('views/workspace/WorkspaceDetails')));

// order routing
const OrderList = Loadable(lazy(() => import('views/order/OrderList')));
const OrderAdd = Loadable(lazy(() => import('views/order/OrderAdd')));
const OrderDetails = Loadable(lazy(() => import('views/order/OrderDetails')));
const CreateInvoice = Loadable(lazy(() => import('views/order/CreateInvoice')));
const Price = Loadable(lazy(() => import('views/pricing/Price')));

// subscription plan routing
// const SubscriptionList = Loadable(lazy(() => import('views/client/SubscriptionList')));
// const SubscriptionDetails = Loadable(lazy(() => import('views/client/SubscriptionDetails')));

//Campaign routing
const CampaignList = Loadable(lazy(() => import('views/client/CampaignList')));
const CampaignDetails = Loadable(lazy(() => import('views/client/CampaignDetails')));
const CreativeList = Loadable(lazy(() => import('views/client/CreativeList')));
const PromotionTextList = Loadable(lazy(() => import('views/client/PromotionTextList')));

//email template routing
const EmailTemplateList = Loadable(lazy(() => import('views/email-template/EmailTemplateList')));
const EmailTemplateDetails = Loadable(lazy(() => import('views/email-template/EmailTemplateDetails')));

// base data routing
const BrandList = Loadable(lazy(() => import('views/basedata/BrandList')));
const BrandDetails = Loadable(lazy(() => import('views/basedata/BrandDetails')));
const SubindustryList = Loadable(lazy(() => import('views/basedata/SubindustryList')));
const SubindustryDetails = Loadable(lazy(() => import('views/basedata/SubindustryDetails')));
const IndustryList = Loadable(lazy(() => import('views/basedata/IndustryList')));
const IndustryDetails = Loadable(lazy(() => import('views/basedata/IndustryDetails')));
const DomainList = Loadable(lazy(() => import('views/basedata/DomainList')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/dashboard',
            element: <Dashboard />
        },
        {
            path: '/user/account-profile/profile',
            element: <AdminProfile />
        },
        {
            path: '/workspace/list',
            element: <WorkspaceList />
        },
        {
            path: '/workspace/details/:id',
            element: <WorkspaceDetails />
        },
        {
            path: '/order/list',
            element: <OrderList />
        },
        {
            path: '/order/create/:id',
            element: <OrderAdd />
        },
        {
            path: '/order/details/:id',
            element: <OrderDetails />
        },
        // {
        //     path: '/client/subscription/list',
        //     element: <SubscriptionList />
        // },
        // {
        //     path: '/client/subscription/details',
        //     element: <SubscriptionDetails />
        // },
        {
            path: '/client/create-invoice',
            element: <CreateInvoice />
        },
        {
            path: '/campaign/list',
            element: <CampaignList />
        },
        {
            path: '/campaign/details/:id',
            element: <CampaignDetails />
        },
        {
            path: '/creative/list',
            element: <CreativeList />
        },
        {
            path: '/text/list',
            element: <PromotionTextList />
        },
        {
            path: '/pricing',
            element: <Price />
        },
        {
            path: '/email-template/list',
            element: <EmailTemplateList />
        },
        {
            path: '/email-template/details/:id',
            element: <EmailTemplateDetails />
        },
        {
            path: '/basedata/brand/list',
            element: <BrandList />
        },
        {
            path: '/basedata/brand/details/:id',
            element: <BrandDetails />
        },
        {
            path: '/basedata/subindustry/list',
            element: <SubindustryList />
        },
        {
            path: '/basedata/subindustry/details/:id',
            element: <SubindustryDetails />
        },
        {
            path: '/basedata/industry/list',
            element: <IndustryList />
        },
        {
            path: '/basedata/industry/details/:id',
            element: <IndustryDetails />
        },
        {
            path: '/basedata/domain/list',
            element: <DomainList />
        }
    ]
};

export default MainRoutes;
