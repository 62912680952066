// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    updated: false,
    count: 0,
    workspaces: [],
    workspace: {},
    products: [],
    productreviews: []
};

const slice = createSlice({
    name: 'workspace',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET CLIENTS
        getWorkspacesSuccess(state, action) {
            state.workspaces = action.payload.workspaceList;
            state.count = action.payload.total;
        },

        // GET CLIENT
        getWorkspaceSuccess(state, action) {
            state.workspace = action.payload;
        },

        // UPDATE
        updatedWorkspace(state, action) {
            console.log(action.payload)
            state.updated = action.payload;
        }
        
    }
});

// Reducer
export default slice.reducer;

export const { updatedWorkspace } = slice.actions;

// ----------------------------------------------------------------------

export function getWorkspaces(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/workspace/list', data,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.getWorkspacesSuccess(response.data));
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addWorkspace(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/workspace/add', data, 
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }
            );
            if(callback){
                callback(response.data.workspace.id);
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getWorkspace(id, callback) {
    return async () => {
        try {
            const response = await axios.get('/api/workspace/details/'+id, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.getWorkspaceSuccess(response.data));
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };

}

export function updateWorkspace(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/workspace/edit', data, 
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.getWorkspaceSuccess(response.data));
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function changeOwner(data, callback) {
    console.log(data)
    return async () => {
        try {
            const response = await axios.post('/api/workspace/change-owner', data, 
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }
            );
            dispatch(slice.actions.updatedWorkspace(true));
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addUser(data, callback) {
    console.log(data)
    return async () => {
        try {
            const response = await axios.post('/api/workspace/user/add', data, 
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }
            );
            dispatch(slice.actions.updatedWorkspace(true));
            if(callback){
                callback(response.data.user);
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function removeUser(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/workspace/user/remove', data, 
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.updatedWorkspace(true));
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function changeRole(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/workspace/user/role', data, 
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }
            );
            dispatch(slice.actions.updatedWorkspace(true));
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function assignLicense(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/workspace/user/assign', data, 
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.updatedWorkspace(true));
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function inviteUser(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/workspace/user/invite', data, 
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.updatedWorkspace(true));
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}