// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    count: 0,
    campaigns: [],
    campaign: {},
    creatives: [],
    creative: {},
    promotionTexts: [],
};

const slice = createSlice({
    name: 'campaign',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET CAMPAIGNS
        getCampaignsSuccess(state, action) {
            state.campaigns = action.payload.campaignList;
            state.count = action.payload.total;
        },

        // GET CAMPAIGN
        getCampaignSuccess(state, action) {
            state.campaign = action.payload;
        },

        // GET CREATIVES
        getCreativesSuccess(state, action) {
            state.creatives = action.payload.creativeList;
            state.count = action.payload.total;
        },

        // GET CREATIVE
        getCreativeSuccess(state, action) {
            state.creative = action.payload;
        },

        // GET PROMOTION TEXTS
        getPromotionTextsSuccess(state, action) {
            state.promotionTexts = action.payload.promotionTextList;
            state.count = action.payload.total;
        },
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getCampaigns(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/campaign/list', data,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.getCampaignsSuccess(response.data));
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getCampaign(id, callback) {
    return async () => {
        try {
            const response = await axios.get('/api/campaign/details/'+id, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.getCampaignSuccess(response.data.campaign));
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };

}

export function getCreatives(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/campaign/creative/list', data,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.getCreativesSuccess(response.data));
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getCreative(id, callback) {
    return async () => {
        try {
            const response = await axios.get('/api/campaign/creative/details/'+id, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.getCreativeSuccess(response.data.creative));
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };

}

export function getPromotionTexts(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/campaign/text/list', data,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.getPromotionTextsSuccess(response.data));
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}