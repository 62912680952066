// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    industry: {},
    industries: [],
    count: 0,
};

const slice = createSlice({
    name: 'industry',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET SUBINDUSTRYS
        getIndustriesSuccess(state, action) {
            state.industries = action.payload.industryList;
            state.count = action.payload.total;
        },

        // GET SUBINDUSTRY
        getIndustrySuccess(state, action) {
            state.industry = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getIndustries(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/industry/list', data,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }
            );
            dispatch(slice.actions.getIndustriesSuccess(response.data));
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addIndustry(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/industry/add', data, 
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }
            );
            if(callback){
                callback(response.data.industry.id);
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getIndustry(id, callback) {
    return async () => {
        try {
            const response = await axios.get('/api/industry/details/'+id, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.getIndustrySuccess(response.data.industry));
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateIndustry(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/industry/edit', data, 
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.getIndustrySuccess(response.data.industry));
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}